/**
 * Represents the type of a quest.
 */
enum QuestType {
  /**
   * An open question.
   */
  OPEN_QUESTION,

  /**
   * A multiple choice question.
   */
  MULTIPLE_CHOICE,

  /**
   * A scan marker question.
   */
  SCAN_MARKER,

  /**
   * A question with the geocacher mini-game.
   */
  GEOCACHER,

  /**
   * A question with the memory mini-game.
   */
  MEMORY,

  /**
   * A question with the shuffle puzzle mini-game.
   */
  SHUFFLE_PUZZLE,
}

export { QuestType };
