import 'reflect-metadata/Reflect';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { config } from '@hulanbv/dashboard-components/distribution/domain/constants/config.contants';
import { colors } from '@hulanbv/dashboard-components/distribution/domain/constants/colors.constants';
import './styles/global.module.css';
import { ClassTransformer, ClassValidator } from '@hulanbv/rijnstraat';

colors.primary = '#154273';
colors.element.danger = '#ef596b';

const render = async () => {
  config.classTransformer = ClassTransformer;
  config.classValidator = ClassValidator;
  const { Main } = await import('./components/main');
  return ReactDOM.render(
    <StrictMode>
      <Main />
    </StrictMode>,
    document.getElementById('root'),
  );
};
render();
