import {
  Transform,
  TransformFnParams,
  TransformOptions,
} from 'class-transformer';

const enclosedRegex = /^{{.*}}$/;
const numberRegex = /^\d+(\.\d+)?$/;

/**
 * Parses a value to it's primitive type when enclosed in {{}}
 * @param value
 * @returns
 */
const toPrimitive = (value: unknown) => {
  if (typeof value !== 'string' || enclosedRegex.test(value) === false) {
    return value;
  }

  // remove the {{}} from the string
  const strippedValue = value.slice(2, -2);

  // check if the value contains a valid number
  if (numberRegex.test(strippedValue)) {
    return +strippedValue;
  }

  // map the values to their written primitives otherwise
  switch (strippedValue) {
    case 'null':
      return null;
    case 'undefined':
      return undefined;
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return strippedValue;
  }
};

/**
 * Decorator which transforms values enclosed in {{}} to their primitive types.
 * @param options
 * @returns
 */
const ToPrimitive = (
  transformFn: (value: any) => any = (value) => value,
  options?: TransformOptions,
): PropertyDecorator =>
  Transform((params: TransformFnParams) => {
    if (Array.isArray(params.value)) {
      return params.value.map((item) => transformFn(toPrimitive(item)));
    }
    return transformFn(toPrimitive(params.value));
  }, options);

export { ToPrimitive, toPrimitive };
