/**
 * Represents the type of an asset.
 */
enum AssetType {
  /**
   * An image asset.
   */
  IMAGE,

  /**
   * A video asset.
   */
  VIDEO,
}

export { AssetType };
