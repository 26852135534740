/**
 * Represents a user role.
 */
enum Role {
  /**
   * A regular user.
   */
  USER,

  /**
   * A content manager.
   */
  CONTENT_MANAGER,

  /**
   * A manager.
   */
  MANAGER,

  /**
   * An administrator.
   */
  ADMIN,
}

export { Role };
